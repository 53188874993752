import React from "react";

export function About() {
    return (
        <div>
            <p className={"mb-4"}>
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
                This is the first paragraph. This is the first paragraph.
            </p>
            <p className={"mb-4"}>
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
                This is the second paragraph. This is the second paragraph.
            </p>
            <p className={"mb-4"}>
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
                This is the third paragraph. This is the third paragraph.
            </p>
        </div>
    );
}